@import "../../assets/stylesheets/variables";


// ==========================================================================================
// TagsPage
// ==========================================================================================

.tags-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $gap-lg 0 calc(2 * $gap-lg);
}

.tags-page__masthead {
  @include for-tablet-down { display: none; }
}


// ==========================================================================================
// TagsEmpty
// ==========================================================================================

.tags-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 $gap-lg;
}

.tags-empty__stage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  padding: $gap-lg;
  border: 1px solid $steel;
  border-radius: $radius-lg;
  @include for-mobile-down {
    height: auto;
    aspect-ratio: 4 / 3;
  }
}
  
.tags-empty__icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin-bottom: $gap-md;
  border: 1px solid $steel;
  border-radius: 50%;
}

.tags-empty__icon {
  font-family: "Font Awesome - Solid";
  font-size: 40px;
  color: $slate;
}

.tags-empty__message {
  font-size: 20px;
  color: $slate;
}


// ==========================================================================================
// TagsList
// ==========================================================================================

.tags-list {
  display: flex;
  flex-direction: column;
  gap: $gap-sm;
  width: 100%;
  margin: 0 0 $gap-lg;
}


// ==========================================================================================
// TagsItem
// ==========================================================================================

.tags-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: $gap-lg;
  border-radius: $radius-lg;
  background: $white;
  box-shadow: $card-shadow;
  @include for-mobile-down { padding: $gap-lg $gap-md; }
}

.tags-item__info {
  display: flex;
  align-items: center;
  gap: $gap-sm;
}

.tags-item__icon {
  font-size: 1.5em;
}

.tags-item__action {
  display: flex;
  align-items: center;
  gap: $gap-xs;
  padding: 10px $gap-md;
  border-radius: $radius-xs;
  color: $slate;
  background: $snow;
  cursor: pointer;
  &:hover { background: $steel; }
}

.tags-item__action__label {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
}

.tags-item__action__icon {
  font-size: 14px;
}


// ==========================================================================================
// TagsScanner
// ==========================================================================================

.tags-scanner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  margin: 0 0 $gap-lg;
  border-radius: $radius-lg;
  overflow: hidden;
  @include for-mobile-down {
    height: auto;
    aspect-ratio: 4 / 3;
  }
}

#qrCanvas {
  width: 600px;
}

.tags-scanner__stage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 100%;
  // height: 400px;
  padding: $gap-lg;
  border: 1px solid $steel;
  border-radius: $radius-lg;
  background-color: $steel;
  @include for-mobile-down {
    height: auto;
    aspect-ratio: 4 / 3;
  }
}

.tags-scanner * {
  width: 100%;
  height: 100% !important;
  padding: 0 !important;
  @include for-mobile-down {  height: auto; }
}

.tags-scanner__alt {
  display: flex;
  align-items: center;
  gap: $gap-md;
  width: 100%;
  > span { font-size: 15px; }
  @include for-mobile-down {
    flex-direction: column;
  }
}

.tags-scanner__alt__action {
  font-weight: 500;
  color: $orange;
  cursor: pointer;
  &:hover { text-decoration: underline; }
}


// ==========================================================================================
// TagsInput
// ==========================================================================================

.tags-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0 $gap-lg;
  border-radius: $radius-lg;
  @include for-mobile-down {
    height: auto;
    aspect-ratio: 4 / 3;
  }
}

.tags-input__stage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 400px;
  margin: 0 0 $gap-lg;
  padding: $gap-md;
  border: 1px solid $steel;
  border-radius: $radius-lg;
  background-color: $steel;
  @include for-mobile-down {
    min-height: 0px;
    aspect-ratio: 4 / 3;
  }
  .form {
    width: 100%;
    max-width: 400px;
  }
  .form__textfield {
    width: 100%;
    background-color: $white;
    @include for-mobile-down {
      text-align: center;
    }
  }
}

.tags-input__instruction {
  margin: 0 0 $gap-lg;
  text-align: center;
}