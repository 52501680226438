@import "../../assets/stylesheets/variables";


// ==========================================================================================
// ErrorsList
// ==========================================================================================

.errors-list {
  position: fixed;
  top: 16px;
  left: 16px;
  right: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  overflow: hidden;
  > span {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
    max-width: 360px;
  }
}


// ==========================================================================================
// ErrorsItem
// ==========================================================================================

.errors-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 12px 20px;
  border-radius: 6px;
  background-color: $burst-light;
}

.errors-item__message {
  font-size: 14px;
  font-weight: 500;
  color: $burst;
}

.errors-item__clear {
  width: 22px;
  height: 22px;
  color: $burst;
  cursor: pointer;
}

.error-enter {
  transform: translateY(-100%);
  opacity: 0;
  &.error-enter-active {
    transform: translateY(0%);
    opacity: 1;
    transition: all 0.3s $out-cubic;
  }
}

.error-leave {
  transform: translateY(0%);
  opacity: 1;
  &.error-leave-active {
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.3s $out-cubic;
  }
}