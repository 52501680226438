// ==========================================================================================
// Mixins
// ==========================================================================================

@mixin for-monitors-up {
  @media (min-width: 1520px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin for-tablet-down {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin for-mobile-down {
  @media (max-width: 639px) {
    @content;
  }
}


// ==========================================================================================
// Colors
// ==========================================================================================

$sun: #f77f00;
$orange: #FB641C;
$burst: #ff4838;
$meadow: #44AE75;
$ocean: #0084FF;

$burst-light: #FFCFCA;

$space: #293640;
$cadet: #495861;
$slate: #84949C;
$steel: #E7EAEE;
$snow: #F5F6F8;
$white: #FFFFFF;


// ==========================================================================================
// Spacing
// ==========================================================================================

$gap-xxs: 4px;
$gap-xs: 8px;
$gap-sm: 12px;
$gap-md: 16px;
$gap-lg: 24px;
$gap-xl: 32px;

$radius-xs: 4px;
$radius-sm: 6px;
$radius-md: 8px;
$radius-lg: 12px;
$radius-xl: 16px;


// ==========================================================================================
// Other variables
// ==========================================================================================

$out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
$card-shadow: 0 12px 24px rgba($space, 0.1);