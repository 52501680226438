@import "../../assets/stylesheets/variables";


// ==========================================================================================
// MediaPage
// ==========================================================================================

.media-page {
  width: calc(100% - 22vw);
  @include for-tablet-down {
    width: 100%;
  }
}


// ==========================================================================================
// MediaSidebar
// ==========================================================================================

.media-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 22vw;
  height: 100%;
  border-left: 1px solid $steel;
  @include for-tablet-down {
    order: 2;
    position: relative;
    top: auto;
    width: 100%;
    padding: 0 0 168px;
    border-left: none;
  }
}

.media-sidebar__back__wrapper {
  display: flex;
  justify-content: space-between;
  padding: $gap-xl $gap-lg;
  border-bottom: 1px solid $steel;
  @include for-tablet-down { display: none; }
}

.media-sidebar__back {
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  color: $slate;
  cursor: pointer;
  &:hover { color: $cadet; }
  &:hover .media-sidebar__back__icon { color: $cadet; }
}

.media-sidebar__back__icon {
  font-family: "Font Awesome - Solid";
  color: $slate;
  margin-right: 9px;
}

.media-sidebar__back__label {
  font-size: 15px;
  font-weight: 500;
}

.media-sidebar__group__list {
  display: flex;
  flex-direction: column;
  gap: $gap-md;
  padding: $gap-lg;
  @include for-tablet-down {
    gap: $gap-sm;
    padding: 0;
  }
}

.media-sidebar__group {
  display: flex;
  gap: $gap-xs;
}

.media-sidebar__group__icon {
  font-size: 18px;
  color: $slate;
}

.media-sidebar__group__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px $gap-xs;
  @include for-tablet-down {
    flex-direction: row;
  }
}

.media-sidebar__group__info {
  font-size: 15px;
  font-weight: 600;
  &.minor {
    color: $slate;
    font-weight: 500;
  }
}

.media-sidebar__action__list {
  display: flex;
  flex-direction: column;
  gap: $gap-xs;
  padding: $gap-lg;
  border-top: 1px solid $steel;
  @include for-tablet-down {
    position: absolute;
    bottom: 0;
    align-items: stretch;
    gap: $gap-xs;
    width: 100%;
    padding: $gap-lg 0;
    border-top: none;
  }
}

.media-sidebar__action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: $gap-sm $gap-md $gap-sm $gap-sm;
  border-radius: $radius-md;
  text-decoration: none;
  color: $slate;
  background-color: $steel;
  cursor: pointer;
  &:hover {
    color: $cadet;
    background-color: rgba($slate, 0.18);
  }
}

.media-sidebar__action__icon { font-size: 14px; }

.media-sidebar__action__info { font-size: 14px; }




// ==========================================================================================
// MediaDisplay
// ==========================================================================================

.mediaDisplay {
  position: relative;
  display: flex;
  margin: $gap-lg 0 $gap-sm;
  @include for-tablet-down {
    margin: 0 -20px 10px;
    border-radius: 0;
  }
  @include for-mobile-down { margin: 0 -20px 8px; }
}

.mediaDisplay__image {
  position: relative;
  width: 100%;
  aspect-ratio: 3 / 2;
  background-size: cover;
  border-radius: $radius-lg;
  @include for-tablet-down { border-radius: 0; }
}


// ==========================================================================================
// MediaThumbnails
// ==========================================================================================

.media-thumbnails {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $gap-xs;
  margin: 0 0 $gap-lg;
  @include for-tablet-down { margin: 0 -8px 20px; }
  @include for-mobile-down { gap: 6px; }
}

.media-thumbnails__item {
  display: flex;
  height: 72px;
  aspect-ratio: 1 / 1;
  border-radius: $radius-md;
  background-size: cover;
  opacity: 0.6;
  &:hover { opacity: 0.8; }
  &.active { opacity: 1; }
  @include for-tablet-down {
    height: 64px;
    border-radius: $radius-sm;
  }
  @include for-mobile-down {
    height: 48px;
    border-radius: 4px;
  }
}