@import "./variables";


// ==========================================================================================
// Font imports
// ==========================================================================================

@font-face {
  font-family: "TT Commons Pro";
  src: url("../fonts/TTCommonsPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TT Commons Pro";
  src: url("../fonts/TTCommonsPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "TT Commons Pro";
  src: url("../fonts/TTCommonsPro-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "TT Commons Pro";
  src: url("../fonts/TTCommonsPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}


// ==========================================================================================
// Global styles
// ==========================================================================================

* {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  font-size: 16px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.01em;
  color: $space;
  @include for-tablet-down { font-size: 24px; }
  @include for-mobile-down { font-size: 22px; }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.01em;
  color: $space;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.01em;
  color: $space;
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.01em;
  color: $space;
}

h5 {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.01em;
}

html, body {
  overscroll-behavior: none;
}

body {
  width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  font-family: "TT Commons Pro", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 1.3;
  color: $cadet;
  background-color: $snow;
}

#root {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  @include for-tablet-down { flex-direction: column; }
}


// ==========================================================================================
// Links & buttons
// ==========================================================================================

a:not([class]),
a[class=""],
.text-link {
  color: $sun;
  text-decoration: none;
  font-size: inherit;
  cursor: pointer;
  &:hover { text-decoration: underline; }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 40px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.01em;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s $out-cubic;
  white-space: nowrap !important;
  background: $burst;
  background: linear-gradient(135deg, $burst 0%, $burst 25%, $sun 100%);
  color: $white;
  border-radius: 8px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  &.alt { background: $slate; }
  &:hover {
    filter: brightness(95%);
    box-shadow: $card-shadow;
  }
  @include for-tablet-down { padding: 16px; }
  @include for-mobile-down { width: 100%; }
}

.button__icon {
  margin-right: 8px;
  font-size: 1.1em;
}


// ==========================================================================================
// Forms
// ==========================================================================================

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0px 0px 8px;
}

.form__textfield {
  width: 100%;
  height: 50px;
  padding: 0px 16px;
  margin-bottom: 12px;
  background: transparent;
  font-family: inherit;
  font-size: 16px;
  border: 1px solid $steel;
  outline: 0;
  transition: border-color 0.2s;
  border-radius: 6px;
  &::placeholder {
    color: $slate;
    font-size: 16px;
  }
}

.form .button { margin-top: 8px; }

.form-switch__phrase {
  font-size: 15px;
  &.reset-password {
    margin: 20px 0 0;
  }
}

.form-switch__link {
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  &:hover { text-decoration: underline; }
}