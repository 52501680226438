@import "../../assets/stylesheets/variables";


// ==========================================================================================
// Masthead
// ==========================================================================================

.masthead {
  display: flex;
  align-items: center;
  height: 96px;
  width: 100%;
  padding: 0 $gap-lg;
  @include for-tablet-down {
    min-height: 0;
    height: auto;
    padding: 0 $gap-md;
  }
}


// ==========================================================================================
// Toolbar
// ==========================================================================================

.toolbar {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid $steel;
  @include for-tablet-down {
    display: none;
  }
}

.toolbar__tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
}

.toolbar__tabs__group {
  display: flex;
  gap: $gap-xxs;
}

.toolbar__tabs__item {
  position: relative;
  display: flex;
  align-items: center;
  gap: $gap-sm;
  padding: $gap-xs $gap-md;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  $color: $slate;
  background-color: $snow;
  cursor: pointer;
  &:hover {
    color: $space;
    background-color: $steel;
  }
  &.active {
    color: $white;
    background-color: $space;
  }
}


// ==========================================================================================
// Main
// ==========================================================================================

.main {
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 0 0 20vw;
  @include for-tablet-down {
    margin: 56px 0 0;
    // order: 1;
  }
}


// ==========================================================================================
// Container
// ==========================================================================================

.container {
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  overflow-y: auto;
  overflow-x: hidden;
  @include for-tablet-down {
    padding: 0 20px 0;
    // order: 1;
  }
  @include for-mobile-down {
    padding: 0 16px 0;
  }
}



// ==========================================================================================
// Promo Bar
// ==========================================================================================

.promobar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: $gap-lg 0 0;
  padding: $gap-md $gap-sm;
  border-radius: $radius-md;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  text-decoration: none;
  color: $cadet;
  background-color: $steel;
  &:hover {
    color: $space;
    background-color: rgba($slate, 0.2);
  }
  & ~ .library-list {
    margin: $gap-sm 0 0;
  }
}

.promobar__icon {
  margin: 0 $gap-sm 0 0;
  font-size: 1.5em;
  color: $slate;
}