@import "../../assets/stylesheets/variables";


// ==========================================================================================
// Nav
// ==========================================================================================

.nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: 100vh;
  padding: 0 $gap-md;
  border-right: 1px solid $steel;
  background-color: $white;
  @include for-tablet-down {
    right: 0;
    bottom: auto;
    flex-direction: row;
    width: 100vw;
    max-width: none;
    height: 56px;
    padding: 0;
  }
}

.nav__brand__link {
  display: flex;
  margin: 0;
  @include for-tablet-down { margin: 0; }
}

.nav__brand__logo {
  width: 11vw;
  max-width: 144px;
  @include for-tablet-down { width: 124px; }
}



// ==========================================================================================
// Menu
// ==========================================================================================

.menu {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 $gap-lg;
  background-color: $white;
  transition: transform 0.3s $out-cubic;
  &.open { transform: translateX(0%); }
  @include for-tablet-down {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 0 $gap-lg $gap-lg;
    width: 320px;
    box-shadow: $card-shadow;
    transform: translateX(100%);
  }
}


// ==========================================================================================
// NavItem
// ==========================================================================================

.menu__item {
  display: flex;
  align-items: center;
  gap: 18px;
  height: 48px;
  padding: 0 $gap-lg;
  font-weight: 500;
  color: $cadet;
  text-decoration: none;
  line-height: 1;
  border-radius: 10px;
  cursor: pointer;
  &:hover { background-color: $snow; }
  &.active {
    background-color: $space;
    color: $white;
    .nav-item__icon {
      background: -webkit-linear-gradient(45deg, $burst, $sun);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  @include for-tablet-down {
    gap: $gap-sm;
    padding: $gap-md;
  }
}

.menu__item__icon {
  height: 17px;
  width: 17px;
}

.menu__item__label {
  font-size: 14px;
}