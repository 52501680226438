@import "../../assets/stylesheets/variables";


// ==========================================================================================
// LibraryPage
// ==========================================================================================

.library-page__masthead {
  @include for-tablet-down { display: none; }
}


// ==========================================================================================
// LibraryEmpty
// ==========================================================================================

.library-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: $gap-lg 0 calc(2 * $gap-lg);
  @include for-tablet-down { margin: $gap-lg 0 calc(2 * $gap-lg); }
}
  
.library-empty__stage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  padding: $gap-lg;
  border: 1px solid $steel;
  border-radius: $gap-sm;
  @include for-mobile-down {
    height: auto;
    aspect-ratio: 4 / 3;
  }
}
  
.library-empty__icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin-bottom: $gap-md;
  border: 1px solid $steel;
  border-radius: 50%;
}

.library-empty__icon {
  font-family: "Font Awesome - Solid";
  font-size: 40px;
  color: $slate;
}

.library-empty__message {
  font-size: 20px;
  color: $slate;
}


// ==========================================================================================
// LibraryList
// ==========================================================================================

.library-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 calc(2 * $gap-lg);
}


// ==========================================================================================
// LibraryGroup
// ==========================================================================================

.library-group {
  display: flex;
  flex-direction: column;
}

.library-group__header {
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  gap: $gap-md;
  margin: 0 calc(-1 * $gap-lg);
  padding: $gap-md $gap-lg;
  background: linear-gradient(rgba($snow, 0.97), rgba($snow, 0.97));
  backdrop-filter: blur(8px);
  @include for-mobile-down {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.library-group__date {
  margin: 0;
  font-style: normal;
}

.library-group__place {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: italic;
  font-weight: 500;
  color: $slate;
  span { font-size: 15px; }
  @include for-mobile-down { margin: calc($gap-xxs / 2) 0 0; }
}

.library-group__place__name {
  color: $cadet;
}

.library-group__place__divider {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $slate;
  margin: 0 calc($gap-xs - 2px) 0 $gap-xs;
}

.library-group__gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: $gap-sm;
  @include for-tablet-down {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 10px;
  }
  @include for-mobile-down {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 6px;
    margin: 0 -10px;
  }
}


// ==========================================================================================
// LibraryItem
// ==========================================================================================

.library-item {
  display: flex;
  width: 100%;
  aspect-ratio: 13 / 12;
  border-radius: $radius-lg;
  background-color: $white;
  overflow: hidden;
  @include for-mobile-down {
    aspect-ratio: 1 / 1;
    border-radius: $radius-sm;
  }
}

.library-item__thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.library-item:hover .library-item__thumbnail { opacity: 0.9; }