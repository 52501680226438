@import "../../assets/stylesheets/variables";


// ==========================================================================================
// AuthPage
// ==========================================================================================

.auth-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 24px;
}

.auth-page__card {
  width: 100%;
  max-width: 480px;
  border-radius: 12px;
  background-color: $white;
  text-align: center;
  box-shadow: $card-shadow;
  & ~ .auth-page__card {
    z-index: 2;
    margin: 12px 0 0;
  }
}

.auth-page__card__inner {
  position: relative;
  padding: 24px 40px;
  @include for-mobile-down { padding: 24px; }
}

a.auth-page__card__alt,
.auth-page__card__alt a {
  color: $sun;
  text-decoration: none;
  &:hover { text-decoration: underline; }
}

.auth-page__logo {
  width: 100%;
  max-width: 180px;
  margin: 16px auto 24px;
}

.form-switch__phrase { color: $cadet; }

a.form-switch__link {
  text-decoration: underline;
  color: $slate;
  &:hover { color: $cadet; }
}